import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import i18n from 'i18n-js';
import { Link, navigate } from 'gatsby';
import {
  Button, Spinner, ListGroup, ListGroupItem
} from 'reactstrap';
import { MdCheckCircle, MdEmail, MdPhoneIphone } from 'react-icons/md';
import { connect } from 'react-redux';
import v from 'voca';

import api from '../../../api';
import { resend2FACodeQuery } from '../../../graphql/auth';
import { setLoginMedium } from '../../../store/actions/authActions';
import OwnlyFullLogo from '../../../assets/images/OwnlyFullLogo';

const i18nOpts = { scope: 'components.auth.sendCode.index' };

const AuthMediums = Object.freeze({
  SMS: 'sms',
  EMAIL: 'email'
});

const SendCode = ({ loginEmail, ...props }) => {
  const [authMedium, setAuthMedium] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (v.isBlank(loginEmail)) navigate('/auth/login');
  }, []);

  const onChange = (newAuthMedium) => {
    setAuthMedium(newAuthMedium);
    props.setLoginMedium(newAuthMedium);
  };

  const onSubmit = () => {
    setSubmitting(true);

    const variables = { email: loginEmail, authMedium };
    api.graphql(resend2FACodeQuery, variables)
      .then(() => {
        navigate('/auth/verifyCode');
      })
      .catch(() => {
        navigate('/auth/login');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="mt-5">
      <Helmet title={i18n.t('title', i18nOpts)} />

      <div className="text-center">
        <Link to="/">
          <OwnlyFullLogo />
        </Link>
      </div>

      <div className="d-flex justify-content-center mt-5">
        <div className="auth-card">
          <h2 className="mb-4 text-center auth-title">{i18n.t('title', i18nOpts)}</h2>

          <p className="text-center text-muted">
            {i18n.t('description', i18nOpts)}
          </p>

          <p className="text-center text-muted mb-4">
            {i18n.t('instructions', i18nOpts)}
          </p>

          <ListGroup>
            <ListGroupItem
              tag="button"
              className="d-flex justify-content-between align-items-center"
              action
              onClick={() => onChange(AuthMediums.EMAIL)}
            >
              <div>
                <MdEmail size={24} className="mr-3" />
                <span>{i18n.t('mediums.email', i18nOpts)}</span>
              </div>

              {authMedium === AuthMediums.EMAIL && (
                <MdCheckCircle size="24" className="col-primary-1" />
              )}
            </ListGroupItem>
            <ListGroupItem
              tag="button"
              className="d-flex justify-content-between align-items-center"
              action
              onClick={() => onChange(AuthMediums.SMS)}
            >
              <div>
                <MdPhoneIphone size={24} className="mr-3" />
                <span>{i18n.t('mediums.sms', i18nOpts)}</span>
              </div>

              {authMedium === AuthMediums.SMS && (
                <MdCheckCircle size="24" className="col-primary-1" />
              )}
            </ListGroupItem>
          </ListGroup>

          {!v.isBlank(authMedium) && (
            <Button type="submit" color="primary" disabled={submitting} block className="mt-5 mb-3" onClick={onSubmit}>
              {submitting && (<Spinner size="sm" className="mr-2" />)}
              {i18n.t('buttons.send', i18nOpts)}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect((store) => ({
  loginEmail: store.auth.loginEmail
}), {
  setLoginMedium
})(SendCode);
