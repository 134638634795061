import v from 'voca';

import { getParam } from './paramsUtils';
import SecureStore from './secureStore';

export function hasOAuth2RedirectUri() {
  return !!getParam('redirect_uri');
}

export function storeOAuth2Params() {
  SecureStore.setAuthRedirectURI(getParam('redirect_uri') || '');
  SecureStore.setAuthState(getParam('state') || '');
}

export function resetOAuth2Params() {
  SecureStore.setAuthRedirectURI('');
  SecureStore.setAuthState('');
}

export function isOauth2Request() {
  return !!SecureStore.getAuthRedirectUri() && !!SecureStore.getAuthState();
}

/**
 * Build redirect uri when login is from third-party applications
 * @return {string|null}
 */
export function getOAuth2RedirectUri(isSuccess) {
  const redirectUri = SecureStore.getAuthRedirectUri();
  const state = SecureStore.getAuthState();
  const { accessToken } = SecureStore.getAuthData() || {};
  if (v.isBlank(redirectUri)) return null;

  const url = new URL(redirectUri);
  if (isSuccess) url.searchParams.set('code', accessToken);
  else url.searchParams.set('error', 'access_denied');
  url.searchParams.set('state', state);

  return url.toString();
}
