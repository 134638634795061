import { navigate } from 'gatsby';

import api from '../../../api';
import { docuSignProcessAuthCodeQuery } from '../../../graphql';

export function getProvider() {
  const { pathname } = window.location;
  return pathname.replace('/auth/', '').replace('/callback', '');
}

export function loginAsync(provider, variables) {
  return docuSignLoginAsync(variables);
}

export function redirectAfterLogin(provider) {
  if (provider === 'docusign') navigate('/admin/integrations/docuSign');
  else navigate('/');
}

export function docuSignLoginAsync(variables) {
  return api.graphql(docuSignProcessAuthCodeQuery, variables)
    .then(({ data: { sent } }) => sent);
}
