import React, { useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { Spinner } from 'reactstrap';
import { Helmet } from 'react-helmet';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import api from '../../../api';
import { validateEmailAndSendResetPasswordLinkQuery } from '../../../graphql/auth';
import OwnlyFullLogo from '../../../assets/images/OwnlyFullLogo';

const i18nOpts = { scope: 'components.auth.resetPasswordLinkSent.index' };

async function validateEmailAsync(variables) {
  return api.graphql(validateEmailAndSendResetPasswordLinkQuery, variables)
    .then(({ data: { sent } }) => Promise.resolve(sent));
}

const ResetPasswordLinkSent = ({ loginEmail }) => {
  const [loading, setLoading] = useState(null);
  const title = i18n.t('title', i18nOpts);

  useEffect(() => {
    if (!loginEmail) navigate('/');
  }, []);

  const resendCode = () => {
    setLoading(true);
    validateEmailAsync({ email: loginEmail })
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        setLoading(false);
        toast.info(i18n.t('messages.submitted', i18nOpts));
      });
  };

  return (
    <div className="mt-5">
      <div className="text-center">
        <Link to="/">
          <OwnlyFullLogo />
        </Link>
      </div>

      <div className="d-flex justify-content-center mt-5">
        <div className="auth-card">
          <Helmet title={title} />

          <h2 className="mb-4 text-center auth-title">{title}</h2>

          { loading ? (
            <div className="text-center my-5"><Spinner size="lg" /></div>
          ) : (
            <div>
              <div className="text-center col-primary-1 py-3">{i18n.t('emailDescription', i18nOpts)}</div>
              <div className="text-center text-danger py-2">{i18n.t('pleaseCheckSpamFolder', i18nOpts)}</div>

              <div className="text-center mt-3">
                <span>{i18n.t('notReceivedLink', i18nOpts)}</span>
                &nbsp;
                <span
                  className="col-primary-1"
                  role="button"
                  onClick={() => resendCode()}
                  aria-hidden
                >
                  {i18n.t('resendLink', i18nOpts)}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect((store) => ({
  loginEmail: store.auth.loginEmail,
}), {
})(ResetPasswordLinkSent);
