import React, { useState } from 'react';
import i18n from 'i18n-js';
import { Link, navigate } from 'gatsby';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import v from 'voca';
import {
  Button, FormGroup, Input, Label, Spinner
} from 'reactstrap';
import { Helmet } from 'react-helmet';

import api from '../../../api';
import { validateEmailAndSendResetPasswordLinkQuery } from '../../../graphql/auth';
import { setLoginEmail } from '../../../store/actions/authActions';
import OwnlyFullLogo from '../../../assets/images/OwnlyFullLogo';

const i18nOpts = { scope: 'components.auth.forgotPassword.index' };

async function validateEmailAsync(variables) {
  return api.graphql(validateEmailAndSendResetPasswordLinkQuery, variables)
    .then(({ data: { sent } }) => Promise.resolve(sent));
}

const ForgotPassword = ({ ...props }) => {
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(null);
  const title = i18n.t('title', i18nOpts);

  const onTextChange = (event) => {
    const { name, value } = event.target;

    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (v.isBlank(form.email)) {
      toast.error(i18n.t('messages.blank', i18nOpts));
      return;
    }
    setLoading(true);
    props.setLoginEmail(form.email);
    validateEmailAsync({ email: form.email })
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        setLoading(false);
        toast.info(i18n.t('messages.submitted', i18nOpts));
        navigate('/auth/resetPasswordLinkSent');
      });
  };

  return (
    <div className="mt-5">
      <div className="text-center">
        <Link to="/">
          <OwnlyFullLogo />
        </Link>
      </div>

      <div className="d-flex justify-content-center mt-5">
        <div className="auth-card">
          <Helmet title={title} />

          <h2 className="mb-4 text-center auth-title">{title}</h2>

          <form onSubmit={onSubmit}>
            <FormGroup className="mb-4">
              <Label for="email">{i18n.t('email', i18nOpts)}</Label>
              <Input
                type="email"
                name="email"
                id="email"
                value={form.email || ''}
                onChange={onTextChange}
              />
            </FormGroup>

            <Button type="submit" color="primary" className="btn-block" disabled={loading}>
              {loading && (<Spinner size="sm" className="mr-2" />)}
              {i18n.t('buttons.confirm', i18nOpts)}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default connect((store) => ({
  currentUser: store.users.currentUser
}), {
  setLoginEmail,
})(ForgotPassword);
