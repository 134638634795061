import React from 'react';
import { Helmet } from 'react-helmet';

import Messages from '../Messages';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'rc-switch/assets/index.css';
import '../../assets/css/style.css';
import '../../assets/css/common.scss';
import '../../assets/css/custom.scss';
import '../../assets/css/print.scss';
import './styles.scss';

const Layout = ({ children }) => (
  <>
    <Helmet bodyAttributes={{ id: 'auth-container' }} />

    <Messages />

    <div className="container">
      {children}
    </div>
  </>
);

export default Layout;
