import React from 'react';

const OwnlyFullLogo = () => (
  <svg width="147" height="42" viewBox="0 0 147 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_dd_46_538)">
      <g clipPath="url(#clip0_46_538)">
        <path d="M26.4833 11.2574L28.9979 10.8013L16.0005 2.5L12.4943 7.3859L12.2634 6.22165L9.24798 6.78484L10.046 10.798L7.20032 14.76L9.52643 14.3385L11.462 24.9172C11.5452 25.3717 11.9527 25.6912 12.4129 25.6912C12.4689 25.6912 12.5249 25.6862 12.5809 25.678L27.6684 23.1222C27.9214 23.0794 28.1472 22.9394 28.295 22.7369C28.4427 22.5327 28.5004 22.2807 28.4545 22.0354L26.4833 11.2607V11.2574ZM13.1973 23.6673L11.4281 13.991L11.4484 13.9877L16.7696 6.26941L24.5732 11.5489L26.3814 21.4343L13.199 23.669L13.1973 23.6673Z" fill="#00CEA6" />
        <path d="M17.1687 10.6234L15.4911 10.9363L15.8205 12.5995L17.5133 12.2636L17.1687 10.6234Z" fill="#D3D300" />
        <path d="M19.5899 11.8881L19.2673 10.2661L17.5507 10.5872L17.8954 12.2241L19.5899 11.8881Z" fill="#D3D300" />
        <path d="M18.2808 14.243L19.9583 13.9301L19.6272 12.2603L17.9344 12.5962L18.2808 14.243Z" fill="#D3D300" />
        <path d="M15.8579 12.9684L16.1822 14.6003L17.8988 14.2775L17.5524 12.6324L15.8579 12.9684Z" fill="#D3D300" />
        <path d="M15.2382 37.5C16.0821 37.5 16.7663 36.7893 16.7663 35.9125C16.7663 35.0358 16.0821 34.3251 15.2382 34.3251C14.3942 34.3251 13.7101 35.0358 13.7101 35.9125C13.7101 36.7893 14.3942 37.5 15.2382 37.5Z" fill="#005141" />
        <path d="M24.0876 37.5C24.9315 37.5 25.6157 36.7893 25.6157 35.9125C25.6157 35.0358 24.9315 34.3251 24.0876 34.3251C23.2436 34.3251 22.5594 35.0358 22.5594 35.9125C22.5594 36.7893 23.2436 37.5 24.0876 37.5Z" fill="#005141" />
        <path d="M34.8896 12.5962H30.5905C30.2424 12.5962 29.9385 12.8202 29.8451 13.1462L28.7907 16.8201H7.20882C6.93716 16.8201 6.68247 16.9436 6.51608 17.1528C6.35138 17.3619 6.29705 17.6336 6.36836 17.8872L9.65718 29.538C9.76075 29.9052 10.1037 30.1588 10.4959 30.1588H25.7176C25.7872 30.1588 25.8568 30.1473 25.9213 30.1325C26.142 30.1802 26.884 30.4173 26.884 31.4251C26.884 32.2205 26.1217 32.3193 25.8008 32.3243H12.7626C12.3365 32.3243 11.9901 32.6602 11.9901 33.0735C11.9901 33.4869 12.3365 33.8228 12.7626 33.8228H25.8008C26.8585 33.8228 28.4308 33.1839 28.4308 31.4235C28.4308 30.0518 27.6583 29.2827 26.9248 28.9089L31.178 14.0964H34.8896C35.3158 14.0964 35.6621 13.7605 35.6621 13.3471C35.6621 12.9338 35.3158 12.5979 34.8896 12.5979V12.5962ZM26.5444 22.9609H23.3999L23.9976 18.5081H27.755L26.5444 22.9609ZM17.5558 23.7101V28.4692H14.2551L13.3382 23.7101H17.5558ZM13.1939 22.9609L12.3348 18.5081H17.5558V22.9609H13.1939ZM18.33 23.7101H22.5204L21.8803 28.4692H18.3283V23.7101H18.33ZM18.33 22.9609V18.5081H23.22L22.6223 22.9609H18.33ZM11.5486 18.5081L12.4078 22.9609H9.60624L8.3498 18.5081H11.5503H11.5486ZM9.81509 23.7118H12.5504L13.4673 28.4709H11.1581L9.81509 23.7118ZM22.6597 28.4709L23.2998 23.7118H26.3407L25.0452 28.4709H22.6597Z" fill="#005141" />
        <path d="M32.0236 15.2392H31.7672V15.0976H32.4396V15.2392H32.1832V15.9029H32.0236V15.2392ZM32.6162 15.0976H32.7859L33.0372 15.4797L33.2885 15.0976H33.4532V15.9029H33.2987V15.3298L33.0423 15.7152H33.0253L32.769 15.3298V15.9029H32.6145V15.0976H32.6162Z" fill="#005141" />
      </g>
    </g>
    <g clipPath="url(#clip1_46_538)">
      <path d="M56.1451 12.6137H56.1094C53.6175 12.6405 51.4683 13.5848 49.7237 15.4122C47.9791 17.2397 47.108 19.4624 47.1366 22.017C47.1628 24.5715 48.0839 26.7748 49.8665 28.5632C51.642 30.3419 53.7983 31.2348 56.2736 31.2153H56.3093C58.8012 31.1885 60.9504 30.2467 62.6949 28.4168C64.4395 26.5893 65.3106 24.3666 65.2821 21.812C65.2559 19.2575 64.3348 17.0543 62.5521 15.2659C60.7766 13.4847 58.6179 12.5917 56.1427 12.6137H56.1451ZM56.1451 15.9197H56.1689C57.7802 15.9075 59.1749 16.4858 60.3173 17.6423C61.474 18.8012 62.0714 20.231 62.0904 21.895C62.1095 23.5444 61.5454 24.9839 60.4125 26.1697C59.2915 27.3554 57.9015 27.9679 56.2807 27.9874H56.2569C54.6456 27.9996 53.2462 27.4213 52.0942 26.2673C50.9447 25.1059 50.3544 23.681 50.3378 22.0316C50.3211 20.3701 50.878 18.9257 52.0014 17.7399C53.1343 16.5541 54.5314 15.9417 56.1522 15.9222L56.1451 15.9197Z" fill="#101828" />
      <path d="M92.0599 14.0752C92.0551 13.6214 91.9171 13.2261 91.6458 12.9016C91.3625 12.5722 91.0341 12.4136 90.5938 12.4136C90.1963 12.4185 89.8607 12.5942 89.5966 12.9284C89.3276 13.2578 89.1943 13.658 89.1991 14.1142L89.3062 22.9075C89.2205 25.1791 89.0396 25.6329 88.3732 26.4478C87.7687 27.1895 86.9238 27.5555 85.7861 27.5702H85.7694C84.8055 27.5775 83.9606 27.17 83.2633 26.3575C82.5564 25.5329 82.1899 24.5179 82.1756 23.3418L82.0637 14.2069C82.059 13.7507 81.9138 13.3554 81.6401 13.0358C81.3664 12.7064 81.0308 12.5405 80.6095 12.5454H80.6024C80.2073 12.5503 79.8669 12.7235 79.5909 13.0602C79.3219 13.392 79.1886 13.7897 79.1934 14.246L79.3052 23.3809C79.3195 24.5593 78.9792 25.5817 78.2914 26.4258C77.6083 27.2627 76.7705 27.6897 75.7971 27.7043C74.807 27.7165 73.9763 27.3091 73.2718 26.4917C72.5245 25.6231 72.3008 24.7301 72.1842 23.4809L72.0723 14.3411C72.0675 13.8849 71.9224 13.4896 71.6463 13.1676C71.3654 12.8406 71.0275 12.6747 70.6443 12.6796H70.5943C70.2087 12.6845 69.8731 12.8577 69.597 13.1968C69.3281 13.5287 69.1948 13.9264 69.1996 14.3826L69.3114 23.5175C69.3424 25.9208 69.866 27.5872 71.0132 28.9218C72.2103 30.315 73.9549 31.1031 75.8042 31.0885H75.8351C77.882 31.0616 79.5528 30.0881 80.8047 28.1972C82.0851 30.0466 83.7631 30.9762 85.7956 30.9591H85.8266C87.7877 30.9347 89.4966 30.1491 90.639 28.7486C91.9338 27.1651 92.1765 25.5231 92.1646 23.2223L92.0528 14.0874L92.0599 14.0752Z" fill="#101828" />
      <path d="M109.477 14.2679C107.997 12.7869 106.207 12.0428 104.155 12.0599H104.124C102.049 12.0843 100.259 12.8675 98.8073 14.3924C97.3555 15.9149 96.6296 17.7667 96.6534 19.8943L96.7534 29.139C96.7581 29.5855 96.9223 29.9686 97.2341 30.2687C97.5459 30.581 97.9172 30.7371 98.3408 30.7347H98.3385L98.3932 30.7322C98.8192 30.7274 99.1881 30.5614 99.4952 30.2418C99.8046 29.932 99.9569 29.5465 99.9498 29.1L99.8498 19.8553C99.8379 18.6329 100.25 17.5667 101.08 16.6834C101.92 15.8026 102.958 15.3488 104.16 15.3342H104.177C105.362 15.3244 106.397 15.7563 107.252 16.6127C108.101 17.4764 108.537 18.5329 108.551 19.7552L108.651 28.9999C108.656 29.4464 108.818 29.8295 109.13 30.1296C109.441 30.4419 109.82 30.598 110.253 30.5956H110.291C110.727 30.5907 111.098 30.4224 111.393 30.1052C111.702 29.7953 111.855 29.4098 111.848 28.9633L111.748 19.7186C111.724 17.5911 110.96 15.7563 109.472 14.2679H109.477Z" fill="#101828" />
      <path d="M139.213 13.292C139.209 12.8626 139.056 12.499 138.756 12.1989C138.468 11.8988 138.114 11.75 137.697 11.7549H137.664C137.245 11.7598 136.89 11.9159 136.598 12.2233C136.305 12.5308 136.16 12.8967 136.164 13.3262L136.262 22.1976C136.274 23.4614 135.853 24.535 134.994 25.4231C134.144 26.3234 133.111 26.7796 131.892 26.7943C130.662 26.8089 129.607 26.3746 128.729 25.4938C127.863 24.6155 127.423 23.5468 127.408 22.2976L127.311 13.4286C127.306 12.9992 127.154 12.6357 126.854 12.3356C126.554 12.0355 126.199 11.8866 125.795 11.8915H125.745C125.338 11.8964 124.99 12.0525 124.695 12.36C124.402 12.6674 124.257 13.0334 124.262 13.4628L124.359 22.3342C124.383 24.452 125.133 26.2453 126.611 27.7166C128.089 29.1976 129.862 29.9271 131.928 29.9027C133.565 29.8856 135.032 29.3732 136.329 28.3656L136.341 29.4977C136.355 30.7347 135.922 31.8107 135.041 32.7232C134.17 33.6235 133.149 34.0797 131.973 34.0944C131.05 34.1041 130.191 33.8797 129.393 33.4137C128.608 32.9623 128.015 32.3548 127.611 31.5935C127.432 31.2568 127.17 31.0177 126.83 30.8762C126.487 30.7444 126.142 30.7444 125.792 30.8713L125.726 30.9055C125.3 31.0567 125.004 31.352 124.845 31.7936C124.674 32.2254 124.695 32.6451 124.909 33.0599C125.561 34.3384 126.506 35.3436 127.746 36.0853C129.029 36.8588 130.452 37.2369 132.011 37.2199C133.958 37.1979 135.667 36.4294 137.147 34.9142C138.668 33.3649 139.418 31.5472 139.394 29.4635L139.218 13.292H139.213Z" fill="#101828" />
      <path d="M119.559 17.8326C119.559 17.8326 119.559 17.8375 119.559 17.8399C119.564 17.7936 119.566 17.7472 119.566 17.6984L119.438 5.76497C119.433 5.32091 119.273 4.94029 118.964 4.62799C118.654 4.32788 118.286 4.18149 117.867 4.18393H117.814C117.393 4.18881 117.029 4.34496 116.727 4.65483C116.422 4.97445 116.272 5.35751 116.279 5.80401L116.408 17.7374C116.408 17.7838 116.412 17.8277 116.417 17.8716C116.417 17.879 116.415 17.8863 116.415 17.8936L116.546 29.91C116.546 30.1418 116.703 30.3345 117.012 30.4882C117.322 30.6493 117.686 30.7274 118.107 30.72H118.159C118.581 30.7152 118.942 30.6298 119.247 30.4614C119.552 30.3004 119.702 30.1027 119.702 29.8734L119.571 17.857C119.571 17.857 119.568 17.8424 119.568 17.835L119.559 17.8326Z" fill="#101828" />
      <path d="M145.205 15.523L143.816 12.3866H144.379L145.35 14.6878L146.322 12.3866H146.884L145.495 15.523H145.205ZM143.714 15.523V12.3866H144.259V15.523H143.714ZM146.441 15.523V12.3866H146.987V15.523H146.441ZM141.669 15.523V12.6593H142.214V15.523H141.669ZM140.714 12.8639V12.3866H143.169V12.8639H140.714Z" fill="#101828" />
    </g>
    <defs>
      <filter id="filter0_dd_46_538" x="0.5" y="0.5" width="41" height="41" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_46_538" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_46_538" result="effect2_dropShadow_46_538" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_46_538" result="shape" />
      </filter>
      <clipPath id="clip0_46_538">
        <rect width="35" height="35" fill="white" transform="translate(3.5 2.5)" />
      </clipPath>
      <clipPath id="clip1_46_538">
        <rect width="100" height="34" fill="white" transform="translate(47 4)" />
      </clipPath>
    </defs>
  </svg>
);

OwnlyFullLogo.propTypes = {};

OwnlyFullLogo.defaultProps = {};

export default OwnlyFullLogo;
