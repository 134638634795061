import React, { useEffect, useState } from 'react';
import {
  Button, FormGroup, Input, Label, Spinner
} from 'reactstrap';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { Link, navigate } from 'gatsby';
import { connect } from 'react-redux';
import { MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md';

import AuthService from '../../../services/AuthService';
import api from '../../../api';
import { loginQuery } from '../../../graphql/auth';
import { hasOAuth2RedirectUri, isOauth2Request, storeOAuth2Params } from '../../../utils/loginUtils';
import { setLoginEmail, setLoginMedium } from '../../../store/actions/authActions';
import SecureStore from '../../../utils/secureStore';
import OwnlyFullLogo from '../../../assets/images/OwnlyFullLogo';

const i18nOpts = { scope: 'components.auth.login.index' };

function loginAsync(variables) {
  return api.graphql(loginQuery, variables)
    .then(({ data: { loginResponse } }) => Promise.resolve(loginResponse));
}

const Login = ({ ...props }) => {
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  const onTextChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const onTogglePasswordShown = () => {
    setPasswordShown((prevShown) => !prevShown);
  };

  const onLogin = (event) => {
    event.preventDefault();

    setLoading(true);

    const { email = '', password = '' } = form;
    const deviceToken = SecureStore.getDeviceToken();
    const variables = { email, password, deviceToken };

    loginAsync(variables)
      .then(async (loginResponse) => {
        props.setLoginEmail(email);

        const { require2FA, authData, authMedium } = loginResponse;
        props.setLoginMedium(authMedium);

        if (require2FA) {
          if (authMedium) navigate('/auth/verifyCode');
          else navigate('/auth/sendCode');
        } else {
          const isOAuth2Request = isOauth2Request();
          await AuthService.login(!isOAuth2Request, authData);
          if (isOAuth2Request) navigate('/auth/consent');
        }
      })
      .catch((e) => toast.error(e.message))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    storeOAuth2Params();

    if (AuthService.isAuthenticated()) {
      if (hasOAuth2RedirectUri()) navigate('/auth/consent');
      else navigate('/');
    }
  }, []);

  const title = i18n.t('title', i18nOpts);

  return (
    <div className="mt-5">
      <div className="text-center">
        <Link to="/">
          <OwnlyFullLogo />
        </Link>
      </div>

      <div className="d-flex justify-content-center mt-5">
        <div className="auth-card">
          <Helmet title={title} />

          <h2 className="text-center auth-title">{title}</h2>
          <p className="text-center mb-5">{i18n.t('description', i18nOpts)}</p>

          <form onSubmit={onLogin}>
            <FormGroup>
              <Label for="email">{i18n.t('email', i18nOpts)}</Label>
              <Input
                type="email"
                name="email"
                id="email"
                value={form.email || ''}
                onChange={onTextChange}
                placeholder={i18n.t('placeholders.email', i18nOpts)}
              />
            </FormGroup>

            <FormGroup className="mb-5">
              <Label for="password">{i18n.t('password', i18nOpts)}</Label>
              <Input
                type={passwordShown ? 'text' : 'password'}
                name="password"
                id="password"
                onChange={onTextChange}
                placeholder={i18n.t('placeholders.password', i18nOpts)}
                className="has-input-icon-append"
              />
              <div className="input-icon-append">
                <div
                  role="button"
                  aria-hidden
                  onClick={onTogglePasswordShown}
                  title={passwordShown ? i18n.t('hidePassword', i18nOpts) : i18n.t('showPassword', i18nOpts)}
                >
                  {passwordShown ? (
                    <MdOutlineVisibilityOff size="1.4rem" className="op-5" />
                  ) : (
                    <MdOutlineVisibility size="1.4rem" className="op-5" />
                  )}
                </div>
              </div>
            </FormGroup>

            <Button type="submit" color="primary" className="btn-block" disabled={loading}>
              {loading && (<Spinner size="sm" className="mr-2" />)}
              {i18n.t('buttons.login', i18nOpts)}
            </Button>

            <div className="text-center mt-4">
              <Link to="/auth/forgotPassword">
                <small className="col-primary-1 font-weight-600">{i18n.t('forgotPassword', i18nOpts)}</small>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default connect((store) => ({
  currentUser: store.users.currentUser
}), {
  setLoginEmail,
  setLoginMedium
})(Login);
