import React, { useEffect, useState } from 'react';
import { Button, Spinner } from 'reactstrap';
import { Link, navigate } from 'gatsby';
import { FaUserCircle } from 'react-icons/all';
import { connect } from 'react-redux';
import i18n from 'i18n-js';

import OwnlyFullLogo from '../../../assets/images/OwnlyFullLogo';
import AuthService from '../../../services/AuthService';
import { getOAuth2RedirectUri } from '../../../utils/loginUtils';

const i18nOpts = { scope: 'components.auth.consent.index' };

const Consent = ({ currentUser }) => {
  const [confirming, setConfirming] = useState(false);
  const [canceling, setCanceling] = useState(false);

  const onConfirm = () => {
    setConfirming(true);
    window.location = getOAuth2RedirectUri(true);
  };

  const onCancel = () => {
    setCanceling(true);
    window.location = getOAuth2RedirectUri(false);
  };

  useEffect(() => {
    if (AuthService.isAuthenticated()) AuthService.login(false).then();
    else navigate('/auth/login');
  }, []);

  if (!currentUser) return null;

  return (
    <div className="mt-5">
      <div className="text-center">
        <Link to="/">
          <OwnlyFullLogo />
        </Link>
      </div>

      <div className="d-flex justify-content-center mt-5">
        <div className="auth-card">
          <h5 className="text-center mb-4">{i18n.t('loggedInAs', i18nOpts)}</h5>

          <div className="d-flex align-items-center">
            <div className="mr-2"><FaUserCircle size="2.5rem" /></div>
            <div className="mr-auto">
              <div className="font-weight-600">{currentUser.name}</div>
              <div className="text-muted">{currentUser.email}</div>
            </div>
          </div>

          <hr />

          <h2 className="text-center my-4">{i18n.t('allowAccess', i18nOpts)}</h2>

          <div>
            <h4>{i18n.t('willAllow', i18nOpts)}</h4>
            <div>
              <ul>
                <li>{i18n.t('readProfile', i18nOpts)}</li>
                <li>{i18n.t('readHomes', i18nOpts)}</li>
                <li>{i18n.t('readLeads', i18nOpts)}</li>
              </ul>
            </div>
          </div>

          <hr />

          <div className="d-flex justify-content-around">
            <Button outline color="secondary" onClick={onCancel} disabled={canceling}>
              {canceling && (<Spinner size="sm" className="mr-2" />)}
              {i18n.t('buttons.cancel')}
            </Button>
            <Button color="primary" onClick={onConfirm} disabled={confirming}>
              {confirming && (<Spinner size="sm" className="mr-2" />)}
              {i18n.t('buttons.confirm')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect((store) => ({
  currentUser: store.users.currentUser
}), {})(Consent);
