import React, { useEffect, useState } from 'react';
import i18n from 'i18n-js';
import { toast } from 'react-toastify';
import v from 'voca';
import { Link, navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import {
  Button, Form, FormGroup, Input, Label, Spinner
} from 'reactstrap';
import { connect } from 'react-redux';

import { loginWith2FAQuery, resend2FACodeQuery } from '../../../graphql/auth';
import api from '../../../api';
import AuthService from '../../../services/AuthService';
import SecureStore from '../../../utils/secureStore';
import OwnlyFullLogo from '../../../assets/images/OwnlyFullLogo';
import { isOauth2Request } from '../../../utils/loginUtils';

const i18nOpts = { scope: 'components.auth.verifyCode.index' };

function verify2FAAsync(code) {
  const deviceToken = SecureStore.getDeviceToken();
  const variables = { code, deviceToken };
  return api.graphql(loginWith2FAQuery, variables);
}

const VerifyCode = ({ loginEmail, loginMedium }) => {
  const [form, setForm] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [resending, setResending] = useState(false);

  useEffect(() => {
    if (v.isBlank(loginEmail)) navigate('/auth/login');
  }, []);

  const onTextChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  const onSubmit = (event) => {
    event.preventDefault();

    setSubmitting(true);

    verify2FAAsync(form.code)
      .then(async ({ data: { authData } }) => {
        const isOAuth2Request = isOauth2Request();
        await AuthService.login(!isOAuth2Request, authData);
        if (isOAuth2Request) navigate('/auth/consent');
      })
      .catch(() => {
        toast.error(i18n.t('messages.invalidCode', i18nOpts));
      }).finally(() => {
        setSubmitting(false);
      });
  };

  const onResend = () => {
    setResending(true);
    const variables = { email: loginEmail, authMedium: loginMedium };
    api.graphql(resend2FACodeQuery, variables)
      .then(() => {
        toast.success(i18n.t('messages.resent', i18nOpts));
      })
      .catch(() => {
        navigate('/auth/login');
      })
      .finally(() => {
        setResending(false);
      });
  };

  return (
    <div className="mt-5">
      <Helmet title={i18n.t('title', i18nOpts)} />

      <div className="text-center">
        <Link to="/">
          <OwnlyFullLogo />
        </Link>
      </div>

      <div className="d-flex justify-content-center mt-5">
        <div className="auth-card">
          <h2 className="mb-4 text-center auth-title">{i18n.t('title', i18nOpts)}</h2>

          <div className="text-center mb-4 text-muted d-block">
            {loginMedium === 'sms' ? i18n.t('smsDescription', i18nOpts) : i18n.t('emailDescription', i18nOpts) }
          </div>

          <Form onSubmit={onSubmit}>
            <FormGroup>
              <Label for="code">{i18n.t('form.code', i18nOpts)}</Label>
              <Input
                type="number"
                name="code"
                id="code"
                value={form.code || ''}
                onChange={onTextChange}
              />
            </FormGroup>

            <Button type="submit" color="primary" disabled={submitting} block className="mt-5 mb-3">
              {submitting && (<Spinner size="sm" className="mr-2" />)}
              {i18n.t('buttons.continue')}
            </Button>

            <small className="text-muted d-block text-center">
              {i18n.t('noReceived', i18nOpts)}
              &nbsp;
              {resending ? (
                <>
                  {i18n.t('resending', i18nOpts)}
                  &nbsp;
                  <Spinner size="sm" />
                </>
              ) : (
                <span
                  onClick={onResend}
                  role="button"
                  aria-hidden
                  className="col-primary-1"
                >
                  {i18n.t('buttons.resend', i18nOpts)}
                </span>
              )}
            </small>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default connect((store) => ({
  loginEmail: store.auth.loginEmail,
  loginMedium: store.auth.loginMedium
}))(VerifyCode);
