import React, { useEffect, useState } from 'react';
import { Router, useLocation } from '@reach/router';
import { navigate } from 'gatsby';

import Login from '../components/auth/Login';
import SendCode from '../components/auth/SendCode';
import VerifyCode from '../components/auth/VerifyCode';
import ForgotPassword from '../components/auth/ForgotPassword';
import Layout from '../layout/auth';
import AuthService from '../services/AuthService';
import ResetPasswordLinkSent from '../components/auth/ResetPasswordLinkSent';
import ResetPassword from '../components/auth/ResetPassword';
import ChangePassword from '../components/auth/ChangePassword';
import LoginCallback from '../components/auth/LoginCallback';
import { isMasterAdmin } from '../utils/authUtils';
import { hasOAuth2RedirectUri } from '../utils/loginUtils';
import Consent from '../components/auth/Consent';

const Auth = () => {
  const [mounted, setMounted] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (AuthService.isAuthenticated()) {
      const isLogin = location.pathname === '/auth/login';
      const isChangingPassword = location.pathname === '/auth/changePassword';
      const isDocuSignCallback = location.pathname === '/auth/docusign/callback';
      const isConsent = location.pathname === '/auth/consent';

      if (isLogin && hasOAuth2RedirectUri()) {
        setMounted(true);
        return;
      }

      if (!isChangingPassword && !isDocuSignCallback && !isConsent) {
        const userPath = isMasterAdmin() ? '/admin/dashboard' : '/admin/overview';
        navigate(userPath);
        return;
      }
    }

    setMounted(true);
  }, []);

  if (!mounted) return null;

  return (
    <Layout>
      <Router basepath="/auth">
        <Login path="/login" />
        <SendCode path="/sendCode" />
        <VerifyCode path="/verifyCode" />
        <ForgotPassword path="/forgotPassword" />
        <ResetPasswordLinkSent path="/resetPasswordLinkSent" />
        <ResetPassword path="/resetPassword" />
        <ChangePassword path="/changePassword" />
        <LoginCallback path="/:provider/callback" />
        <Consent path="/consent" />
        <Login path="/" />
      </Router>
    </Layout>
  );
};

export default Auth;
