import React from 'react';
import api from '../../../api';
import { validateUserInvitationTokenQuery } from '../../../graphql/auth';

import ResetPassword from '../ResetPassword';

async function validateInvitationAuthTokenAsync(variables) {
  return api.graphql(validateUserInvitationTokenQuery, variables)
    .then(({ data: { isValid } }) => Promise.resolve(isValid));
}

const ChangePassword = () => (
  <ResetPassword
    validateTokenFunction={validateInvitationAuthTokenAsync}
    forFirstLogin
  />
);

export default ChangePassword;
