import React, { useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import i18n from 'i18n-js';
import v from 'voca';

import { getParam } from '../../../utils/paramsUtils';
import { loginAsync, getProvider, redirectAfterLogin } from './utils';
import { ProviderNames } from '../../../constants';
import OwnlyFullLogo from '../../../assets/images/OwnlyFullLogo';

const i18nOpts = { scope: 'components.auth.loginCallback.index' };

const LoginCallback = () => {
  const provider = getProvider();
  const code = getParam('code');
  const state = getParam('state');

  useEffect(() => {
    if (v.isBlank(provider) || v.isBlank(code)) navigate('/');

    const variables = { input: { state, code } };
    let waitTime = 5000;

    loginAsync(provider, variables)
      .then(({ success, message }) => {
        if (success) toast.success(message);
        else {
          toast.error(message, { autoClose: false });
          waitTime = 10000;
        }
      })
      .catch(() => {
        toast.error(i18n.t('messages.error', i18nOpts));
      })
      .finally(() => {
        setTimeout(() => {
          redirectAfterLogin(provider);
        }, waitTime);
      });
  }, []);

  return (
    <div className="text-center mt-5">
      <Link to="/">
        <OwnlyFullLogo />
      </Link>

      <div className="d-flex justify-content-center mt-5">
        <div className="auth-card">
          <Helmet title={i18n.t('title', i18nOpts)} />

          <h3 className="mb-4">{i18n.t('title', { ...i18nOpts, provider: ProviderNames[provider] })}</h3>

          <p>{i18n.t('description', i18nOpts)}</p>

          <div className="text-center my-5"><Spinner size="lg" /></div>
        </div>
      </div>
    </div>
  );
};

export default LoginCallback;
