import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import i18n from 'i18n-js';
import {
  Button, FormGroup, Input, Label, Spinner
} from 'reactstrap';
import v from 'voca';
import { toast } from 'react-toastify';

import { getParam } from '../../../utils/paramsUtils';
import api from '../../../api';
import { resetPasswordQuery, validateResetPasswordTokenQuery } from '../../../graphql/auth';
import { getError } from '../../../utils/requestUtils';
import StrongPasswordInput from '../../ui/StrongPasswordInput';
import OwnlyFullLogo from '../../../assets/images/OwnlyFullLogo';

const i18nOpts = { scope: 'components.auth.resetPassword.index' };

async function resetPasswordAsync(variables) {
  return api.graphql(resetPasswordQuery, variables)
    .then(({ data: { reset } }) => Promise.resolve(reset))
    .catch((res) => Promise.reject(res));
}

function validateResetPasswordTokenAsync(variables) {
  return api.graphql(validateResetPasswordTokenQuery, variables)
    .then(({ data: { isValid } }) => Promise.resolve(isValid));
}

const ResetPassword = ({ validateTokenFunction, forFirstLogin }) => {
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(null);
  const [isStrongPassword, setIsStrongPassword] = useState(false);
  const title = i18n.t('title', i18nOpts);
  const canSubmit = isStrongPassword && !v.isBlank(form?.confirmPassword);

  const onTextChange = (event) => {
    const { name, value } = event.target;

    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  useEffect(() => {
    const token = getParam('token');
    validateTokenFunction({ token })
      .then((isValid) => {
        if (!isValid) {
          const errorMessage = getErrorMessage();
          toast.error(errorMessage);
          setTimeout(() => navigate('/'), 3000);
        }
      });
  }, []);

  const onValidate = (valid) => {
    setIsStrongPassword(valid);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    const { password, confirmPassword } = form;
    const token = getParam('token');
    if (canSubmit) {
      if (password !== confirmPassword) {
        setLoading(false);
        toast.error(i18n.t('dontMatchError', i18nOpts));
        return;
      }
      const input = { token, password, forFirstLogin };
      resetPasswordAsync({ input })
        .then((reset) => {
          if (reset) {
            setLoading(false);
            toast.success(i18n.t('messages.success', i18nOpts));
            navigate('/auth/login');
          }
        }).catch((er) => {
          setLoading(false);
          const error = getError(er);
          let isInvalidTokenError = true;
          let errorMessage = getErrorMessage();

          if (error?.password) {
            errorMessage = error?.password?.join('\n');
            isInvalidTokenError = false;
          }

          toast.error(errorMessage);
          if (isInvalidTokenError) setTimeout(() => navigate('/'), 3000);
        });
    } else {
      setLoading(false);
      toast.error(i18n.t('blankError', i18nOpts));
    }
  };

  const getErrorMessage = () => (forFirstLogin
    ? i18n.t('messages.invalidFirstLoginToken', i18nOpts)
    : i18n.t('messages.invalidResetToken', i18nOpts));

  return (
    <div className="mt-5">
      <div className="text-center">
        <Link to="/">
          <OwnlyFullLogo />
        </Link>
      </div>

      <div className="d-flex justify-content-center mt-5">
        <div className="auth-card">
          <Helmet title={title} />

          <h2 className="mb-4 text-center auth-title">{title}</h2>

          <form onSubmit={onSubmit}>
            <FormGroup className="mb-4">
              <Label for="password">{i18n.t('password', i18nOpts)}</Label>
              <StrongPasswordInput onChange={onTextChange} afterValidate={onValidate} />
            </FormGroup>

            <FormGroup className="mb-4">
              <Label for="confirmPassword">{i18n.t('confirmPassword', i18nOpts)}</Label>
              <Input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                value={form.confirmPassword || ''}
                onChange={onTextChange}
              />
            </FormGroup>

            <Button type="submit" color="primary" className="btn-block" disabled={loading || !canSubmit}>
              {loading && (<Spinner size="sm" className="mr-2" />)}
              {i18n.t('buttons.reset', i18nOpts)}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

ResetPassword.propTypes = {
  validateTokenFunction: PropTypes.func,
  forFirstLogin: PropTypes.bool
};

ResetPassword.defaultProps = {
  validateTokenFunction: validateResetPasswordTokenAsync,
  forFirstLogin: false
};

export default ResetPassword;
